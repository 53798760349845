import Section from 'components/Section'
import { useMemoizedContentGetter } from 'utils/contentful'
import { useViewportSmallerThan } from 'utils/viewport'
import { BREAKPOINTS } from 'constants/viewport'
import { TitleDivider } from 'components/TitleDivider'
import { CarouselV2 } from 'components/Carousels/CarouselV2'
import { MediaImageStandard, TypographyLongForm } from 'components/Contentful'
import { VideoDialog } from 'components/VideoDialog'
import { StatGrid } from 'components/StatGrid'

const ItemComponentCarousel = ({ item, index, activeIndex }) => {
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)
  return (
    <div className={'relative'}>
      <div className={'[&_img]:rounded-[10px] overflow-hidden'}>
        <MediaImageStandard
          content={item?.image}
          layout="fill"
          height={isMobile ? 208 : 364}
          width={isMobile ? 208 : 394}
        />
      </div>
      {activeIndex === index && (
        <div
          className={
            'bg-[linear-gradient(90deg,#dff7fd_0%,#e4dafb_68.43%,#ddf3ff_95.43%)] shadow-[0_1.6553px_3.3106px_0_rgb(16_24_40_/_5%)] text-[#154199] absolute -translate-x-2/4 whitespace-nowrap px-4 py-2 rounded-[10px] left-2/4 -bottom-5 animate-fade-up'
          }
        >
          <TypographyLongForm
            variant="bodySm"
            fontWeight="bold"
            component="span"
            content={item?.title}
          />
        </div>
      )}
    </div>
  )
}

const ItemComponentMessage = ({ item }) => {
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)
  return (
    <>
      <div
        className={'[&_strong]:font-medium max-w-[803px] pb-2'}
        data-testid="testimonial-copy-container"
      >
        <TypographyLongForm content={item?.message} variant={isMobile ? 'bodySm' : 'bodyLg'} />
      </div>
      {item?.statGrid && (
        <div className={'w-full pb-8'}>
          <StatGrid grid={item?.statGrid?.fields} />
        </div>
      )}
      {item?.videoDialog && (
        <VideoDialog
          content={item?.videoDialog}
          trackOpen={{
            nav_link_section: 'Testimonials',
          }}
        />
      )}
      {item?.disclaimer && (
        <TypographyLongForm
          content={item?.disclaimer}
          variant="bodySm"
          className={'text-[#42546b] text-center max-w-[803px] w-full pt-8'}
        />
      )}
    </>
  )
}

function TestimonialCarouselV2({ content }) {
  const { title, eyebrow, gridDebtConsolidationTestimonials, event, backgroundConfigJson } =
    useMemoizedContentGetter(content, [
      'eyebrow',
      'title',
      'gridDebtConsolidationTestimonials',
      'event',
      'backgroundConfigJson',
    ])

  return (
    <TestimonialCarouselV2Module
      title={title}
      eyebrow={eyebrow}
      gridDebtConsolidationTestimonials={gridDebtConsolidationTestimonials?.gridItemsCollection}
      event={event}
      jsonConfig={backgroundConfigJson?.jsonContent}
    />
  )
}

function TestimonialCarouselV2Module({
  title,
  eyebrow,
  gridDebtConsolidationTestimonials,
  event,
  jsonConfig,
}) {
  const { items = [] } = gridDebtConsolidationTestimonials || {}
  const backgroundConfig = jsonConfig?.background ?? 'bgDefault'

  return (
    <Section
      className={'flex flex-col px-0 py-12 lg:py-16 lg:px-0'}
      contain={true}
      variant={backgroundConfig}
      data-testid="TestimonialCarouselV2_section"
    >
      {(title || eyebrow) && (
        <TitleDivider
          disableTitleTag={true}
          eyebrow={eyebrow}
          eyebrowBlack
          title={title}
          eyebrowClassName={
            title
              ? 'tracking-[3.5px] leading-[16.8px] pb-2 md:pb-4'
              : 'tracking-[3.5px] leading-[16.8px] pb-8'
          }
        />
      )}

      <CarouselV2
        event={event}
        gridItems={items}
        ItemComponentCarousel={ItemComponentCarousel}
        ItemComponentMessage={ItemComponentMessage}
        withMessages
      />
    </Section>
  )
}

export { ItemComponentCarousel, ItemComponentMessage, TestimonialCarouselV2 }

export default TestimonialCarouselV2
